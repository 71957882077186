import revive_payload_client_LLIC9dZcju from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sVezKX2gCW from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gmgzC9QHU1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rnHX0Fv5jO from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ukX4jX3TSH from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gUf1WOiDb3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9j2pBJqh2B from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/apps/abmeldung-digital/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_ZPPOs1cUkV from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.6.2_vue@3.5.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/abmeldung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_EX7P8dE1Jr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_zKCF1lbFbm from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_l25YPdSHpp from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_La6HC1MjIU from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_gNjtGMqgRI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_l5e2chhuOn from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.5.2_vite@5.4.8_vue@3.5.10/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_RXAtTRUscj from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import event_directive_YCbI7d1p4o from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_JCMvpbgZ2W from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import feathers_r9RHv5VuHz from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_ZWaWEo2Woe from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_EGbfpheBQT from "/vercel/path0/layers/base/plugins/trpc.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/abmeldung-digital/plugins/sentry.client.ts";
export default [
  revive_payload_client_LLIC9dZcju,
  unhead_sVezKX2gCW,
  router_gmgzC9QHU1,
  payload_client_rnHX0Fv5jO,
  navigation_repaint_client_ukX4jX3TSH,
  check_outdated_build_client_gUf1WOiDb3,
  chunk_reload_client_9j2pBJqh2B,
  plugin_zbacBAXhj0,
  plugin_vue3_ZPPOs1cUkV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EX7P8dE1Jr,
  slideovers_zKCF1lbFbm,
  modals_l25YPdSHpp,
  colors_La6HC1MjIU,
  plugin_client_gNjtGMqgRI,
  plugin_l5e2chhuOn,
  plugin_RXAtTRUscj,
  event_directive_YCbI7d1p4o,
  script_JCMvpbgZ2W,
  feathers_r9RHv5VuHz,
  cookie_banner_client_ZWaWEo2Woe,
  trpc_EGbfpheBQT,
  sentry_client_shVUlIjFLk
]